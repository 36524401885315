import classNames from 'classnames';
import styles from '../../../../styles/pages/home.module.scss';
import Image from 'next/image';

type BtnCarousel = {
  moveSlide: () => void;
  direction: string;
};

export default function BtnCarousel({ moveSlide, direction }: BtnCarousel) {
  return (
    <button
      onClick={moveSlide}
      className={classNames(
        styles.btnSlide,
        direction === 'next' ? styles.next : styles.prev
      )}
    >
      {direction === 'next' ? (
        <Image
          src="/icons/arrow-right-blue.png"
          alt="Flecha Derecha"
          width="9px"
          height="15px"
        />
      ) : (
        <Image
          src="/icons/arrow-left-blue.png"
          alt="Flecha Izquierda"
          width="9px"
          height="15px"
        />
      )}
    </button>
  );
}

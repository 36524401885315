import styles from '../../../../styles/pages/negocios/index.module.scss';
import Icon from '../../../base/icon';
import EventCardHomePST from './event-card';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { apiRestGet } from '../../../../services/request';
import {UbicationType} from "../../../../hooks/ubicationHook";

type EventSectionType = {
  isTourist: boolean;
  ubication?: UbicationType
};

export interface RequestBody {
  cant_filas: number
  fecha_inicial: Date
  fecha_final: Date
  pagina: number
  id_tipo_evento: number
  latitude?: number
  longitude?: number
  formatted_address?: string
}

export default function EventSection({ ubication,isTourist }: EventSectionType) {
  const [selectedDate] = useState(new Date());
  const t = useTranslations('Home');
  const [eventsList, setEventsList] = useState([]);
  const router = useRouter();

  const getEvents = async () => {
    let requestBody: RequestBody;

    const dt = await ubication;
    requestBody = {
      cant_filas: 3,
      fecha_final: startOfMonth(selectedDate),
      fecha_inicial: endOfMonth(selectedDate),
      formatted_address: dt?.ubication?.formatted_address ?? ' , , ',
      id_tipo_evento: 1,
      pagina: 1,
      latitude: ubication.latitude,
      longitude: ubication.longitude
    }


    const result = await apiRestGet(
      process.env.NEXT_PUBLIC_OFFER_EVENT_READ_SERVICE_URL + 'events/home',
      requestBody
    );

    setEventsList(result.data?.events ?? []);
  };

  useEffect(() => {
    getEvents().then();
  }, []);

  useEffect(() => {
    getEvents().then();
  }, [ubication]);

  return (
    <>
      <section className={styles.EventSection}>
        {!isTourist && (
          <>
            <h2>¿Quiénes se pueden registrar?</h2>
            <p>
              Se pueden regristrar todos los prestadores de servicios turísticos
              que operen en la Región Pacífico de Colombia y cuenten con un{' '}
              <strong>RNT activo</strong>.
            </p>
          </>
        )}
        <h2>{t('ProximosEventos')}</h2>
        <p>{t('subTitleEvents')}</p>

        <div className={styles.GridEvent}>
          {eventsList.map((event, key) => {
            return <EventCardHomePST event={event} />;
          })}
        </div>

        <button
          className="btn yellow"
          onClick={() => {
            router.push('/eventos').then();
          }}
        >
          <Icon icon="calendar" size={16} /> {t('agenda')}
        </button>
      </section>
    </>
  );
}

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import styles from '../../../../styles/pages/home.module.scss';
import BtnCarousel from './btnCarousel';

type CarouselType = {
  autoPlay?: boolean;
};

export default function Carousel(props: CarouselType) {
  const images = [
    'Choco.jpg',
    'ValleDelCauca.jpg',
    'Cauca.jpg',
    'Narino.jpg',
  ];
  const [slideIndex, setSlideIndex] = useState(0);

  const nextSlide = () => {
    if (slideIndex !== images.length - 1) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === images.length - 1) {
      setSlideIndex(0);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 0) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 0) {
      setSlideIndex(images.length - 1);
    }
  };

  const moveDot = (index) => {
    setSlideIndex(index);
  };

  useEffect(() => {
    if (props.autoPlay) {
      const interval = setInterval(() => {
        nextSlide();
      }, 6000);

      return () => clearInterval(interval);
    }
  });

  return (
    <div className={styles.containerSlider}>
      {images.map((obj, index) => {
        return (
          <div
            key={obj}
            className={classNames(
              styles.slide,
              slideIndex === index ? styles.activeAnim : ''
            )}
          >
            <img
              src={`images/home-turista/carousel/${obj}`}
              alt="img carousel"
            />
          </div>
        );
      })}

      <BtnCarousel moveSlide={nextSlide} direction={'next'} />
      <BtnCarousel moveSlide={prevSlide} direction={'prev'} />

      <div className={styles.containerDots}>
        {Array.from({ length: 4 }).map((item, index) => (
          <div
            key={index}
            onClick={() => moveDot(index)}
            className={classNames(
              styles.dot,
              slideIndex === index ? styles.active : ''
            )}
          ></div>
        ))}
      </div>
    </div>
  );
}
